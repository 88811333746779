import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-musicos',
  templateUrl: './musicos.component.html',
  styleUrls: ['./musicos.component.css']
})
export class MusicosComponent implements OnInit {

  buscador = true;


  constructor() { }

  ngOnInit() {

    window.scrollTo(0, 0);
  }

}
